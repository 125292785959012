"use client";

import Link from "next/link";
import { Button, Checkbox, Form, Input, notification } from "antd";
import { signIn } from "next-auth/react";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { useRouter } from 'next/navigation'
import Image from "next/image";
import { images } from "@/themes";
// TODO: remove the icon images we imported here and the image if not in use https://github.com/GingerEdu/frontend/commit/82d45b1e914ae4e378a5972dcd9fce27653e1585


export default function LoginPage() {
  const router = useRouter()

  const onFinish = async (values: any) => {
    const username = values.username;
    const password = values.password;

    const response = await signIn('credentials', { username, password, redirect: false});
    if (response?.ok === true && response?.status === 200) {
      await router.replace('/chat');
    } else {
      notification.error({
        message: 'Error',
        description: 'Invalid credentials provided. Please try again.'
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="flex flex-col gap-8 items-center justify-center min-h-screen">
      <div className="absolute top-0 left-0 m-10">
        <Link href="/">
          <Image
            src={images.gingerlogo}
            width={150}
            height={50}
            alt="GingerEdu Logo"
          />
        </Link>
      </div>
      <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="w-full max-w-[400px] bg-white p-10 rounded-lg md:shadow-lg"
          layout="vertical" 
        >
          <p className="font-medium text-xs text-gray-400!">
            {`Don\'t have an account?`}
            {` `}
            <Link href="/sign-up" className="text-ginger-green font-medium">
              Sign up
            </Link>
          </p>
          <p className="text-ginger-green text-2xl font-medium my-8">
            Welcome
          </p>
          <Form.Item
            label="Email"
            name="username"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input
              prefix={<MailOutlined className="text-gray-500" />}
              size="large"
              type="email"
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              prefix={<LockOutlined className="text-gray-500" />}
              size="large"
            />
          </Form.Item>

          <div className="flex justify-between">
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item>
              <Link
                href="/forgot-password"
                className="text-ginger-green font-medium"
              >
                Forgot password?
              </Link>
            </Form.Item>
          </div>

          <Form.Item className="w-full">
            <Button
              type="primary"
              htmlType="submit"
              className="w-full bg-ginger-green"
              size="large"
            >
              Login
            </Button>
          </Form.Item>
      </Form>   
    </div>
  );
}
